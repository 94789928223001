<template>
    <div class="springAwakening2023_main">

        <nav-bar header-title="超鹿游泳周边商城" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head"/>
        <div class="headbg">
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/swimming-mall/poster.png" class="head-img" alt=""/>
            <img src="https://img.chaolu.com.cn/ACT/temp-activity/swimming-mall/title.png" class="title-head-img" alt=""/>
            <div @click="showRule = true" class="rule-btn row-center-center">规则</div>
        </div>
        <!--团课卡-->
        <div class="goodsbox col-start-center" style="margin-top: 1.6vw">
            <div class="basebg" v-for="(item, key) in cardMap" :key="key">
                <div class="goods-model" v-for="(citem, index) in item" :key="index">
                    <div class="pub_full">
                        <div class="goods-img"><img :src="citem.picUrl"/></div>
                        <div class="pub_onefull col-between-start">
                            <div>
                                <div class="goods-name">{{ citem.itemName }}</div>
                                <div class="invalid-time" v-if="citem.expiryDateDescribe"
                                     v-html="citem.expiryDateDescribe"></div>
                                <div class="label-line">
                                    <div class="label-model" v-if="citem.discountRate">
                                        <div class="scale">{{ citem.discountRate }}</div>
                                    </div>
                                    <div class="label-model" v-if="citem.save">
                                        <div class="scale">{{ citem.save }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row-between-center">
                                <div class="price-line row-start-end">
                                    <span class="dl">¥</span>
                                    <span class="price">{{ citem.salePrice }}</span>
                                    <span v-if="citem.originalPrice" class="oldprice">¥{{ citem.originalPrice }}</span>
                                </div>
                                <div class="pub_avg line2">
                                    <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }"
                                         @click="buyCard(citem, 20)">{{
                                            buttonText(citem)
                                        }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="bottom-tips row-center-center"><i></i><span>已经滑到底部啦</span><i></i></div>
        </div>
        <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
            <div class="pop-rule-title">
                {{ rules.title }}
                <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false"/>
            </div>
            <div class="pop-rule-content">
                <div v-for="(item, index) in rules.content" :key="index">{{ item }}</div>
            </div>
        </van-popup>

        <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
            <div class="buy-title">
                选择购买城市
                <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showCity = false"/>
            </div>
            <div class="city-list">
                <div v-for="(item, index) in cityList" :key="index" class="city-model"
                     :class="{ 'city-active': cityIndex === index }" @click="cityIndex = index">
                    {{ item.name }}
                    <div class="checkbox"></div>
                </div>
                <div class="next" @click="showSure = cityIndex != undefined ? true : false">下一步</div>
            </div>
        </van-popup>
        <van-popup v-model="showSure" position="center" class="popsure">
            <div class="sure-title">提示</div>
            <div class="sure-content">您当前购买的自助健身卡，仅限<span v-if="cityIndex != undefined">{{
                    cityList[cityIndex].name
                }}</span>市所有门店使用，请确认后再付款！
            </div>
            <div class="sure-sub" @click="next">我知道了</div>
        </van-popup>

        <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
            <div class="buy-title">
                选择购买城市
                <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showCity = false"/>
            </div>
            <div class="city-list">
                <div v-for="(item, index) in cityList" :key="index" class="city-model"
                     :class="{ 'city-active': cityIndex === index }" @click="cityIndex = index">
                    {{ item.name }}
                    <div class="checkbox"></div>
                </div>
                <div class="next" @click="showSure = cityIndex != undefined ? true : false">下一步</div>
            </div>
        </van-popup>

    </div>
</template>
<script>
import {newAppBack, initBack, appPay, appPaySuccess} from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'

import {scheduleProtocol, protocol} from '@/views/month-card/const'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'
import {isEmpty} from '@/lib/utils'

const activityNo = 'shiWuSale';
const webUrl = `${window.location.origin}/#/temp-activity/swimming-mall`
export default {
    components: {
        navBar,
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            showShare: false,

            showRule: false,
            showSure: false,

            inviteTeacherId: '', // 教练id
            inviteUserInfo: {}, // 邀请人信息

            activeKey: 3,

            cityList: [
                {name: '福州市'},
                {name: '厦门市'},
                {name: '广州市'}
            ],
            cardTitle: [
                '', '送朋友，一起来运动！', '送伴侣，健身更迷人！', '送亲人，健康添福气！'
            ],

            showCity: false,
            cityIndex: undefined,

            cardMap: [],//  礼品卡
            microCoursePackageMap: [],//  小班课
            equityCardMap: [], // 团课月卡
            zzkList: [], // 自助卡筛选后展示用
            zzkIndex: 0, // 自助卡第几类
            ticketEquityCardMap: [], // 自助卡二重数组
            scheduleCoursePackageMap: {}, // 团课
            perCoursePackageMap: {},// 私教
            rules: {
                title: '活动规则',
                content: [
                    '1、商品为实物，购买后需至福州中庚红鼎天下店核销兑换。',
                    '2、商品购买后不支持退款。商品均设置有效期，请在有效期内尽快使用，逾期失效。',
                    '3、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。'
                ]
            },
            stickyHeight: 0,
        }
    },
    methods: {
        newAppBack,
        isEmpty,
        buttonText(item = {}) {
            const status = item.itemStatus;
            const limit = item.hasBuyLimit;
            const json = {
                'NOT_STARTED': '待开启',
                'EXPIRED': '已结束',
                'SOLD_OUT': '已售罄',
                'HAVE_BUY': '已购买',
                'CAN_BUY': '立即购买',
                'NOT_BUY': '不可购买'
            }
            let text = json[status] || '立即购买';
            if (status == 'CAN_BUY' && limit) {
                text = '限量抢';
            }
            return text;
        },

        next() {
            this.showSure = false;
            this.showCity = false;
            if (this.cityIndex !== undefined) {

                const payitem = this.ticketEquityCardMap[this.zzkIndex][this.cityIndex];
                console.log(this.ticketEquityCardMap[this.zzkIndex]);
                console.log(payitem)
                this.buyCard(payitem, 14);
            }
        },
        getActivityInfo() {
            this.$axios
                .post(this.baseURLApp + '/userDistribute/bindUser', {
                    ...this.params,
                    saleTeacherId: this.inviteTeacherId,
                })
                .then((res) => {


                });
        },
        getGoodList() {
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: "spinner",
                message: '加载中...',
            })
            this.$axios.post(`${this.baseURLApp}/anniversary/sale/getItemList`, this.params).then(res => {
                const r = res.data;
                this.cardMap = r.cardMap  //  礼品卡
                toast.clear()
            }).catch(r => {
                toast.clear()
            })
        },
        async buyCard(item, type) {

            if (item.itemStatus !== 'CAN_BUY') {
                this.$toast('很遗憾，您暂不满足购买条件。')
                return
            }
            const res = await this.checkCanBuy(item);
            if (!res) {
                this.getGoodList()
                return
            }

            // 服务端需要 做个转换
            const obj = JSON.parse(JSON.stringify(item))
            obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

            this.payAction(obj, type)
        },
        payAction(info, type) {
            let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
            let payData = {
                subject: info.itemName,
                extendsJson: {
                    appVersion: '2.1.11',
                    seriesName: '超鹿会员商城',
                    inviteUserId: this.inviteId,
                },
                totalAmount: info.salePrice,
                venueId: '31',
                goodsNum: type === 12 ? '1' : undefined,
                isApp: '1',
                type: type, // 礼品卡12  课包10 月卡
                userId: this.params.userId,
                token: this.params.token,
                extendsType: '4',
                mouldId: info.itemId,
                whetherPrivacy: 0,
                originalPriceStr: '',
                title: info.itemName,
            }
            if (this.appTypeStr === 'mini') {
                payData.wxPayType = 'applet'
            }
            appPay(payData, url);
            console.log(payData, url);
            if (this.appTypeStr !== 'mini') {
                appPaySuccess().then(() => {
                    this.paySuccess();
                    this.$toast('支付成功');
                })
            }
        },
        paySuccess() {
            setTimeout(() => {
                this.getGoodList();
                this.getInviteUserInfo();
            }, 500)
        },
        checkCanBuy(info) {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', {
                ...this.params,
                itemId: info.itemId
            }).then((r) => {
                this.$toast.clear()
                return r.code
            }).catch(() => {
                this.$toast.clear()
                return 0
            })
        },
        openBuyCity(index) {
            this.cityIndex = undefined;
            this.zzkIndex = index
            this.showCity = true;
        },
        // 接受好友邀请
        getInviteUserInfo() {
            this.$axios.post(this.baseURLApp + '/userDistribute/bindUser', {
                ...this.params,
                saleTeacherId: this.inviteTeacherId
            }).then((res) => {
                this.inviteUserInfo = res.data
                this.inviteTeacherId = res.data.shareTeacherId
            })
        },
    },
    mounted() {
        setTimeout(() => {
            this.stickyHeight = this.$refs.head.$el.offsetHeight
        }, 1000)
    },
    async created() {
        initBack();
        this.inviteTeacherId = this.$route.query.teacherId || this.$route.query.inviteTeacherId
        await this.$getUserId();
        this.params = {
            activityNo,
            userId: this.userId,
            token: this.token,
            hasWx: true
        }
        this.getInviteUserInfo()
        this.getGoodList()
        appPaySuccess().then(() => {
            this.paySuccess();
        })
    },

}
</script>
<style lang="less" scoped>
.springAwakening2023_main {
    min-height: 100vh;
    background: #51A8EB;
    padding-bottom: 60px;
    box-sizing: border-box;
    div {
        box-sizing: border-box;
    }
    img {
        vertical-align: top;
        width: 100%;
    }

    .leftbg .van-icon-arrow-left {
        width: 64px;
        height: 64px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        text-align: center;
        line-height: 64px;
        font-size: 38px !important;
    }

    .share {
        width: 46px;
        height: 46px;
    }

    .headbg {
        position: relative;
    }
    .title-head-img{
        height: 57px;
        width: 427px;
        position: absolute;
        bottom: 38px;
        left: 161px;
    }
    .rule-btn{
        width: 88px;
        height: 56px;
        font-size: 24px;
        color: #FFFFFF;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 28px 0 0 28px;
        position: absolute;
        top: 318px;
        right: 0;
    }
    .bind-coach {
        width: 686px;
        height: 88px;
        background: linear-gradient(88deg, #FDD9A4 0%, rgba(252, 211, 153, 0) 100%, rgba(253, 217, 164, 0) 100%);
        border-radius: 124px;
        margin: 0 auto 24px;
        padding: 0 18px;

        .avator {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 2px solid #fff;
            margin: 0 24px 0 0;
        }
    }

    .goods-title {
        height: 100px;
        margin-top: 40px;
    }

    .tofu {
        padding: 16px 32px 0;
        position: relative;
        background: #FF9926;

        .tm {
            width: 216px;
            height: 72px;
            background: rgba(0,0,0,0.1);
            border-radius: 12px;
            color: #FFFFFF;
            margin-bottom: 16px;

            &.big {
                width: 334px;
            }

            &.active {
                color: #311C07;
                background: linear-gradient(180deg, #FFFFFF 0%, #FFEDB8 100%);
            }
        }


    }

    .goodsbox {
        padding: 0 32px 14px;
        margin-top: 40px;

        .classimg {
            height: 58px;
            width: fit-content;
            padding: 0 68px;
            background-image: url(https://img.chaolu.com.cn/ACT/normal-coach-mall/title-bg-l.png), url(https://img.chaolu.com.cn/ACT/normal-coach-mall/title-bg.png);
            background-size: 68px 58px;
            background-position: left top, right top;
            background-repeat: no-repeat;
            color: #8D3500;
        }

        .seerule {
            width: 160px;
            height: 42px;
            margin: 28px auto 44px;
        }
        .see-title{
            width: 452px;
            height: 40px;
            margin: 0 auto 32px;
        }
        .basebg {
            width: 100%;

            background: rgba(255,255,255,0.2);
            border-radius: 16px;
            position: relative;
            padding: 28px 24px 12px;
            margin-bottom: 42px;
            min-height: 300px;
        }

        .pdt {
            padding-top: 94px;
        }

        .coner {
            word-break: keep-all;
            position: absolute;
            top: -10px;
            left: 0;
            padding: 0 24px;
            height: 72px;
            border-radius: 16px 2px 16px 2px;
            background: linear-gradient(181deg, #FEDEAD 0%, #FCD298 100%);
            color: #922C00;
        }

        .goods-model {
            background: #fff;
            border-radius: 16px;
            margin-bottom: 20px;
            padding: 26px;
            //height: 292px;

            .goods-img {
                width: 180px;
                height: 180px;
                background-color: rgba(255, 255, 255, 0.7);
                margin-right: 30px;
                border-radius: 10px;
                overflow: hidden;
                flex-shrink: 0;
                img {
                    object-fit: cover;
                }
            }

            .goods-name {
                font-weight: bold;
                color: #242831;
                font-size: 30px;
                padding-top: 2px;
            }
            .describe{
                width: 96px;
                height: 36px;
                border-radius: 4px;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 20px;
                background: #FF6C01;
                margin-bottom: 20px;
            }
            .label-line {
                margin: 14px 0 14px;
                white-space: nowrap;

                .label-model {
                    padding: 0 10px;
                    height: 30px;

                    background: linear-gradient(90deg, #FFE8B5 0%, #FFD7C3 100%);
                    border-radius: 4px;
                    color: #B12E1D;
                    font-weight: bold;
                    font-size: 20px;
                    margin-right: 12px;
                    line-height: 1;
                    display: inline-flex;
                    align-items: center;

                    .scale {
                        transform: scale(0.9);
                        transform-origin: 50% 50%;
                    }
                }

                .limit {
                    padding: 0;
                    background: #79AF52;
                }
            }
            .invalid-time {
                color: #3C454E;
                font-size: 20px;
                line-height: 16px;
                margin-top: 16px;
            }
            .price-line {

                .dl {
                    font-size: 22px;
                    color: #F03C18;
                    font-weight: bold;
                    margin-right: 4px;
                }

                .price {
                    font-size: 32px;
                    color: #F03C18;
                    font-weight: bold;
                    margin-right: 9px;
                    //position: relative;
                    line-height: 32px;
                    //top: 2px;
                }

                .oldprice {
                    text-decoration: line-through;
                    color: #6C727A;
                    font-size: 20px;
                }
            }

            .line2 {
                align-items: center;
                margin-top: 14px;
                .buy {
                    width: 136px;
                    height: 50px;
                    background: linear-gradient(0deg, #F35947 0%, #FBA27B 100%);
                    border-radius: 25px;
                    color: #fff;
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                }

                .gray {
                    color: #6C727A;
                    background: #DDDDDD;
                    box-shadow: none
                }
            }
        }
    }

    .pop-rule {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        .pop-rule-title {
            height: 132px;
            font-weight: bold;
            background: linear-gradient(180deg, #DDF2FF 0%, #FCFEFF 100%);
            font-size: 32px;
            text-align: center;
            line-height: 130px;
            position: relative;

            .pop-rule-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

        .pop-rule-content {
            color: #242831;
            font-size: 26px;
            padding: 0 52px 60px;
            max-height: 720px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            white-space: pre-line;

            div {
                margin-bottom: 46px;
                line-height: 1.6;
            }
        }
    }


    .popcity {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .buy-title {
            font-weight: bold;
            color: #242831;
            font-size: 32px;
            border-bottom: 1px solid #EEEEEE;
            height: 120px;
            padding: 0 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .buy-close {
            font-size: 36px;
        }

        .city-list {
            padding: 8px 32px 32px;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 32px;
                font-weight: bold;

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }

            .city-active {
                border: 4px solid #242831;

                .checkbox {
                    width: 48px;
                    height: 48px;
                    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
                    background-size: 100% 100%;
                    border: none;
                    margin: 0;
                }
            }
        }

        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin-top: 64px;
            font-weight: bold;
            text-align: center;
        }
    }

    .popsure {
        width: 590px;
        border-radius: 12px;
        padding: 50px 40px;

        .sure-title {
            color: #242831;
            font-size: 36px;
            font-weight: bold;
            text-align: center;
        }

        .sure-content {
            color: #3C454E;
            font-size: 24px;
            line-height: 1.6;
            margin: 36px 0 48px;
            text-align: center;

            span {
                color: #F03C18;
            }
        }

        .sure-sub {
            border-radius: 8px;
            background: #FFDE00;
            color: #000;
            font-weight: bold;
            font-size: 32px;
            line-height: 96px;
            text-align: center;
        }
    }

    @bei: 1.5px;

    .canvascss {
        position: fixed;
        bottom: -200%;
        // top: 0;
        width: @bei*654;
        height: @bei*1120;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }


        .canvascss_left {
            position: absolute;
            left: @bei*32;
            bottom: @bei*36;
            z-index: 1;
            width: @bei*100;
            height: @bei*100;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .canvascss_name {
            position: absolute;
            left: @bei*162;
            bottom: @bei*112;
            z-index: 1;
            color: #242831;
            font-weight: bold;
            font-size: @bei*28;
            width: @bei*252;
        }

        .canvascss_right {
            position: absolute;
            right: @bei*34;
            bottom: @bei*24;
            width: @bei*140;
            height: @bei*140;
            border-radius: @bei*14;
            overflow: hidden;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/border.png);
            background-size: 100% 100%;
            padding: @bei*18;

            img {
                width: @bei*104;
                height: @bei*104;
            }
        }

    }
    .bottom-tips{
        color: #FFFFFF;
        font-size: 22px;
        i{
            width: 60px;
            height: 1px;
            background: #FFFFFF;
        }
        span{
           margin: 0 30px;
        }
    }

    /deep/ .canvas-box {
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;

        .create-img {
            width: 100% !important;
        }
    }
}</style>
